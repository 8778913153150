import { useState, useEffect, useRef,scrollContainerRefs } from "react";
import Grid from '@mui/material/Grid'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from 'react';

import Button from '@mui/material/Button';
import { ReactSession } from 'react-client-session';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';



const style = {
  position: 'absolute',
  top: '29%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',

  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
//  top: '50%',
const ActionComponent = ({ open, onClose, data , sendclass,docketBump,ItemsId,itemsStatus,LastDocketStatus, AllItemIds}) => {
    
    
    const [sendclasse, setSendclasse] = React.useState("");
    const currentFontVal = ReactSession.get('currentFont');
     const handleContentClick = (e) => {
    e.stopPropagation(); // Stop the event from bubbling up to the modal's onClose handler
  };
   
   
    
  
    
  return (
    <div className="">
      
      <Modal
         className="backdrop-blur"
         open={open}
         onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
         BackdropComponent={Backdrop}
        BackdropProps={{
          style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
        }}
      >
        <Box sx={style}  className={`${currentFontVal} modalcls`} onClick={handleContentClick}>
        <div className={sendclass}>
          <Grid item xs={12} md={12}>
            <div id='stats' className="">
              <div className='colaction'>
                  Order Number
                <Typography variant='h6' sx={{ mb: 6 }}>
                  {data}
                </Typography>
              </div>
             
             </div>
            </Grid>
          </div>
          <div id="modal-modal-description" sx={{ mt: 3 }}>
            <Grid item xs={12} md={12}>
                  <Grid item xs={12} md={12} className="notecls">
                    <Typography variant='h6' sx={{ mb: 6 }}>
                         
                    </Typography>
                 </Grid>
                 <Grid xs={12} md={12} className="notecls" >
                   <Typography variant='h6' sx={{ mb: 6 }}>
                     <Button  onClick={() => { docketBump(ItemsId,itemsStatus,LastDocketStatus,AllItemIds); onClose(); }} variant="contained">{itemsStatus==5?"Undo Bump":"Bump"}</Button>
                    </Typography>
                 </Grid>
                 
            </Grid>
          </div>
        </Box>
      </Modal>
    </div>

    );
 
};

export {ActionComponent} ;
