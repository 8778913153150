import React, { useState ,useEffect } from "react";
import useForm from "./useForm";
import { Link } from "react-router-dom";
import { useNavigate  } from "react-router-dom";
import Grid from '@mui/material/Grid'
import { withStyles } from "@material-ui/styles";
import config from '../../config';
import {Box,List,Typography,useTheme,Button,Tab,Tabs} from "@mui/material";
import { SnackbarProvider, useSnackbar } from 'notistack';

import axios from 'axios';
import { ReactSession } from 'react-client-session';
import validate from "./LoginFormValidationRules";


import UndoIcon from '@mui/icons-material/Undo';
const SettingComponent = ({activeIndexn,handleChangesn}) => {
const [activeIndex, setActiveIndex] = useState(activeIndexn);
const { values, errors, tagsScreen  , tagsScreenPreValue, handleChange, handleSubmit , handleChangelast, handleSubmitLast  } = useForm(
    
    validate
    );
const handleChanges = (_, activeIndex) => setActiveIndex(activeIndex);
const currentUserNameVars = ReactSession.get('kdsName');
const [selectedItems, setSelectedItems] = useState(ReactSession.get('selectedtags'));
const [tagsScreenValue, setTagsScreenValue] = useState(ReactSession.get('alltags'));
const [uniqueItems, setUniqueItems] = useState([]);
const [nextScreen, setNextScreen] = useState(false);
const [checkedValues, setCheckedValues] = useState([]);
const { enqueueSnackbar } = useSnackbar();




useEffect(() => {
if(activeIndexn === 0){
  // console.log("test0");
}if(activeIndexn === 1){
  // console.log("test1");
}
  
}, [activeIndexn])
const onClickSubmit = async(data) => {
  
  const array = selectedItems;
  ReactSession.set("selectedtags", selectedItems);
  const result = array.join(',');
  const url = config.apiUrl + "kitchen-device-tag-update";
  const getToken = ReactSession.get("authToken")
  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
    // Set headers
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken}`
    };
  try {
    const response = await axios.post(url, {
      tags :result
   },{headers});

   ReactSession.set("currentTags", result);
  
   toast.success("Device tag updated successfully!");
  
 } catch (error) {
  if (error.response && error.response.status === 401) {
    // Handle 401 Unauthorized error
    ReactSession.set("currentTags", null);
    ReactSession.set("kdsName", null);
    ReactSession.set("authToken", null);
    ReactSession.set("userData", null);
    ReactSession.set("kdsName", null);
    ReactSession.set("userSetting", null);
    ReactSession.set("alltags", null);
    window.location.reload(); // Reload the page
  } else {
    // Handle other errors
    console.error(error);
  }
 }
};

const onClickSaveName = async(data) => {
  const name = values.userName ? values.userName : ReactSession.get("kdsName") ;
  ReactSession.set("kdsName", name);
  const getToken = ReactSession.get("authToken")
  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
    // Set headers
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken}`
    };
  const url = config.apiUrl + "kds-device-update";
  try {
    const response = await axios.post(url, {
      name :name
   },{headers});
   enqueueSnackbar('Device name updated successfully!', {variant: 'success',anchorOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }},);
   
   //setTimeout(window.location.reload(), 70000);
   
 } catch (error) {
  if (error.response && error.response.status === 401) {
    // Handle 401 Unauthorized error
    ReactSession.set("currentTags", null);
    ReactSession.set("kdsName", null);
    ReactSession.set("authToken", null);
    ReactSession.set("userData", null);
    ReactSession.set("kdsName", null);
    ReactSession.set("userSetting", null);
    ReactSession.set("alltags", null);
    window.location.reload(); // Reload the page
  } else {
    // Handle other errors
    console.error(error);
  }
 }
  //
};

 
    const handleCheckboxChange = (event) => {
        try {
          // Access the target element from the event
          const target = event.target;
          const { value, checked } = event.target;
          if (checked) {
            setCheckedValues((prevValues) => [...prevValues, value]);
          } else {
            setCheckedValues((prevValues) => prevValues.filter((val) => val !== value));
          }
          // Check if the target element exists and has a value
          if (target && target.value !== undefined) {
            const { value, checked } = target;
    
            // If the checkbox is checked, add the item to the selectedItems array
            if (checked) {
              setSelectedItems(prevItems => [...prevItems, value]);
              // Add the value to the uniqueItems array if it's not already present
              if (!uniqueItems.includes(value)) {
                setUniqueItems(prevItems => [...prevItems, value]);
              }
            } else {
              // If the checkbox is unchecked, remove the item from the selectedItems array
              setSelectedItems(prevItems => prevItems.filter(item => item !== value));
              // Remove the value from the uniqueItems array
              setUniqueItems(prevItems => prevItems.filter(item => item !== value));
            }
          } else {
            console.error("Target element or its value is undefined");
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            // Handle 401 Unauthorized errorReactSession.set("currentTags", null);
            ReactSession.set("kdsName", null);
            ReactSession.set("authToken", null);
            ReactSession.set("userData", null);
            ReactSession.set("kdsName", null);
            ReactSession.set("userSetting", null);
            ReactSession.set("alltags", null);

            window.location.reload(); // Reload the page
          } else {
            // Handle other errors
            console.error(error);
          }
        }
       
    };
    return (
      <div style={{ display: "flex", position: 'relative' }} className="flex justify-center items-center h-screen">
        
        
       <Box m="10px" className="">
          <div className="form-container outer tabone">
            
              <div className="form-form">
                  <div className="form-form-wrap">
                      <div className="form-container">
                          <div className="form-content">
                              <div className="avatar avatar-xl">
                              
                              </div>
                              
                                  <form className="text-left form" onSubmit={handleSubmit} noValidate>
                                    <div className="field field-wrapper input">
                                      <label id="username-field"  className="label">Kitchen Display Name</label>
                                      <div className="control">
                                        
                                          <input
                                          autoComplete="off"
                                          className={`input form-control ${errors.userName && "is-danger"}`}
                                          type="text"
                                          name="userName"
                                          
                                          onChange={handleChange}
                                          value={values.userName || currentUserNameVars}
                                          required
                                        />
                                        {errors.userName && (
                                          <p className="help is-danger">{errors.userName}</p>
                                        )}
                                      </div>
                                    </div>
                                    
                                    <div className="field d-sm-flex justify-content-between">
                                      <div className="control field-wrapper">
                                        <button
                                          type="button"
                                          onClick={() => onClickSaveName() }
                                          className="leftbtn button usernamebutton btn btn-primary login-form-button ant-btn is-block is-info is-fullwidth"
                                        >
                                         
                                          Submit
                                        </button>
                                        
                                      </div>
                                    </div>
                                   
                                  </form>
                              


                          </div>

                      </div>

                  </div>
              </div>
          </div>
        </Box>
       
      </div>
    );
  };
  
  const VerticalTabs = withStyles((theme) => ({
    flexContainer: {
      flexDirection: "column"
    },
    indicator: {
      display: "none"
    }
  }))(Tabs);
  
  const MyTab = withStyles((theme) => ({
    root: {
      backgroundColor: "#ccc",
      // borderRadius: theme.shape.borderRadius
    },
    wrapper: {
      backgroundColor: "#ddd",
      // padding: theme.spacing(2),
      // borderRadius: theme.shape.borderRadius
    },
    selected: {
      color: "tomato",
      borderBottom: "2px solid tomato"
    }
  }))(Tab);
  
  const TabContainer = (props) => {
    return (
      <Typography component="div" style={{ padding: 24 }}>
        {props.children}
      </Typography>
    );
  };
  

export default SettingComponent;
