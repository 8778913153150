import { useState, useEffect, useRef,scrollContainerRefs } from "react";
import Grid from '@mui/material/Grid'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from 'react';

import Button from '@mui/material/Button';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { ReactSession } from 'react-client-session';
import { overFlow } from "@syncfusion/ej2/diagrams";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  height: "100%",
  overflow:"auto"

};

// const style = {
//   position: 'absolute',
//   top: '45%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 500,
//   height:500,
//   bgcolor: 'background.paper',

//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };



const ModalComponentWithOutTags = ({updateListData, toggleItemStatus ,sendClassModal, open, onClose, data , allData , listData , sendclass}) => {
    
       const [sendclasse, setSendclasse] = React.useState("");
       
       const [collectData, setCollectData] = React.useState([]);
       const currentFontVal = ReactSession.get('currentFont');
       let isCancelledLabelShown = false
       let isCompletedLabelShown = false;
       let isNewLabelShown = false;
       const groupingTags = ReactSession.get("groupingTags");
     const handleContentClick = (e) => {
    e.stopPropagation(); // Stop the event from bubbling up to the modal's onClose handler
  };
  
  

  const [updatedListData, setUpdatedListData] = React.useState(listData || []);
  useEffect(() => {
    if (listData) {
      setUpdatedListData(listData);
    }
  }, [listData]); 
  const toggleItem = (id) => {
    
     const updatedCollectData = collectData.map((item) => {
      if (item.completed === true) {
        return item.id === id ? { ...item, completed: false, kitchen_status: 0 } : item;
      } else if (item.completed === false) {
        return item.id === id ? { ...item, completed: true, kitchen_status: 6 } : item;
      }
      return item;
    });

    setCollectData(updatedCollectData);
    updateListData(updatedCollectData);
   
    // Update updatedListData with the new collectData
    setUpdatedListData(collectData.map(item => {
      if(item.completed == true){
       return item.id === id ? { ...item, completed:false, kitchen_status:0 } : item
      }else if(item.completed == false){
        return item.id === id ? { ...item, completed:true, kitchen_status:6 } : item
      }
    }
     
    ));
  };
  
  useEffect(() => {
    if (updatedListData) {
      const modifiedData = updatedListData.map((item) => {
        let checkStatus;
        if (item.kitchen_status === 6) {
          checkStatus = true;
        } else {
          checkStatus = false;
        }
        return {
          ...item,
          completed: checkStatus,
        };
      });
      setCollectData(modifiedData);
    }
  }, [updatedListData]);
     
  return (
    <div>
      
      <Modal
        className="modal-overlay backdrop-blur"
        open={open}
         onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
         BackdropComponent={Backdrop}
        BackdropProps={{
          style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
        }}
         
      >
      {/* className="modalcls" */}
        <Box sx={style}  className={`${currentFontVal} modalcls`} onClick={handleContentClick}>
        {Array.isArray(allData) && (
        <div className={sendclass} style={{'background-color' : sendClassModal }}>
        {allData.map((item, index) => {
           
        return ( <Grid item xs={12} md={12}>
            <div id='statse' className="">
              <div className='flex col upercls'>
                
              <span className="table-cls">{item.table_name} [{item.floor_name}]</span>
               
                <div className="timeCls"><span className="time-span">{item.formattedTime}</span><span className="name-span">{item.customer !== null ? item.customer.name : ""}</span></div>
               
              </div>
              <div className='flex col'>
                  
                <Typography variant='h8' sx={{ mb: 6 }}>
                  {item.order_number}
                </Typography>
              </div>
             </div>
            </Grid>);
         })} 
          </div>
         )}
           
       
          <div id="modal-modal-description" sx={{ mt: 3 }}>
          {collectData && (
           <ol className='my-list-modal card-links orderList'>
              {/* ${(i.token_attributes.length > 0)?"hasitemcls":"modelitem"} */} 
                
                {collectData.map((i, j) => (
                (i.kitchen_status == 0 || i.kitchen_status == "0") && (
                  <div key={i.id}>
                    {!isNewLabelShown && (isNewLabelShown = true) && (
                      <li className='shopping__itemh newClss'>
                        <label htmlFor="potatoes" className='shopping__label newCls'>
                          New
                        </label>
                      </li>
                    )}
                    <li className={`shopping__itemh   `}   onClick={() => {toggleItemStatus(i.id, i.kitchen_status);toggleItem(i.id)}}>
                      <label for="potatoes" className="shopping__label" onClick={() => {toggleItemStatus(i.id, i.kitchen_status);toggleItem(i.id)}} style={{
                            textDecoration:  i.completed ? 'line-through' : 'none',
                            textDecorationColor:  i.completed ? 'red' : 'inherit', // Add text-decoration-color
                            }}>{ i.qty} X {i.name} {(i.is_qr == 1) && (<QrCodeScannerIcon />)}</label>
                         
                          {(i.spice_level != null) && (
                              <ol style={{'background-color' : "rgb(215 221 214)" }} className="my-list style-3 underOl">
                              <li className="shopping__item" >
                              <label htmlFor="potatoes" className="shopping__label">
                                Spice Level : { i.spice_level}
                              </label>
                            </li>
                            </ol>  
                          )}
                  
                      <ol className="my-list style-3 underOl">
                        {  i.token_attributes && i.token_attributes.map(j => (
                         
                          <li className="shopping__item modifire">{ j.qty} X {j.name}</li>
                          
                         
                        ))}
                        </ol>
                        {(i.notes != null) && (
                              <ol style={{'background-color' : "rgb(236 231 177)" }} className="my-list style-3 underOl">
                              <li className="shopping__item" >
                              <label htmlFor="potatoes" className="shopping__label" title={ i.notes}>
                                Special Instructions : { i.notes}
                              </label>
                            </li>
                            </ol>
                             
                          )}
                          {(i.textarea != null) && (i.textarea_label != null) && (
                              <ol style={{'background-color' : "rgb(236 231 177)" }} className="my-list style-3 underOl">
                              <li className="shopping__item" >
                              <label htmlFor="potatoes" className="shopping__label">
                              { i.textarea_label}  : { i.textarea}
                              </label>
                            </li>
                            </ol>
                          
                        )}
                    </li>
                  </div>
                    )
                ))}
                 {collectData.map((i, k) => (
                   (i.kitchen_status == 6 || i.kitchen_status == "6") && (
                    <div key={i.id}>
                      {!isCompletedLabelShown && (isCompletedLabelShown = true) && (
                        <li className='shopping__itemh completedClss'>
                          <label htmlFor="potatoes" className='shopping__label completedCls'>
                            Completed
                          </label>
                        </li>
                      )}
                    <li className={`shopping__itemh   ${(i.token_attributes.length > 0)?"hasitemcls":"modelitem"}  `} onClick={() => { toggleItemStatus(i.id, i.kitchen_status) ; toggleItem(i.id)}}> 
                      <label for="potatoes" className="shopping__label" onClick={() => {toggleItemStatus(i.id, i.kitchen_status);toggleItem(i.id)}} style={{
                        textDecoration: i.kitchen_status === 6 || i.kitchen_status === 3 || i.completed ? 'line-through' : 'none',
                        textDecorationColor: i.kitchen_status === 6 || i.kitchen_status === 3 || i.completed ? 'red' : 'inherit', // Add text-decoration-color
                        pointerEvents: i.kitchen_status === 3 ? 'none' : 'auto',
                      }}>{ i.qty} X {i.name} {(i.is_qr == 1) && (<QrCodeScannerIcon />)}</label>
                      {(i.spice_level != null) && (
                          <ol style={{'background-color' : "rgb(215 221 214)" }} className="my-list style-3 underOl">
                          <li className="shopping__item" >
                          <label htmlFor="potatoes" className="shopping__label">
                            Spice Level : { i.spice_level}
                          </label>
                        </li>
                        </ol>  
                      )}
                      <span className="shopping__span"></span>
                      <ol className="my-list style-3 underOl">
                        {  i.token_attributes && i.token_attributes.map(j => (
                         
                          <li className="shopping__item modifire">{ j.qty} X {j.name}</li>
                          
                         
                        ))}
                        </ol>
                        {(i.notes != null) && (
                              <ol style={{'background-color' : "rgb(236 231 177)" }} className="my-list style-3 underOl">
                              <li className="shopping__item" >
                              <label htmlFor="potatoes" className="shopping__label" title={ i.notes}>
                                Special Instructions : { i.notes}
                              </label>
                            </li>
                            </ol>
                             
                          )}
                          {(i.textarea != null) && (i.textarea_label != null) && (
                              <ol style={{'background-color' : "rgb(236 231 177)" }} className="my-list style-3 underOl">
                              <li className="shopping__item" >
                              <label htmlFor="potatoes" className="shopping__label">
                              { i.textarea_label}  : { i.textarea}
                              </label>
                            </li>
                            </ol>
                          
                        )}
                    </li>
                    </div>
                    )
                ))}

               {collectData.map((i, l) => (
                (i.kitchen_status === 3) && (
                  <div key={i.id}>
                    {!isCancelledLabelShown && (isCancelledLabelShown = true) && (
                        <li className='shopping__itemh cancelledClss'>
                          <label htmlFor="potatoes" className='text-center shopping__label cancelledCls'>
                            Cancelled
                          </label>
                        </li>
                      )}
                    <li className={`shopping__itemh   `}  style={{ pointerEvents: i.kitchen_status === 3 ? 'none' : 'auto' }}  onClick={() => {toggleItemStatus(i.id, i.kitchen_status);toggleItem(i.id)}}>
                      <label for="potatoes" className="shopping__label"
                        
                       onClick={() => {toggleItemStatus(i.id, i.kitchen_status);toggleItem(i.id)}} 
                       style={{
                        textDecoration: i.kitchen_status === 3 || i.completed ? 'line-through' : 'none',
                        textDecorationColor: i.kitchen_status === 3 || i.completed ? 'red' : 'inherit',
                        pointerEvents: i.kitchen_status === 3 ? 'none' : 'auto',
                      }} >{ i.qty} X {i.name} {(i.is_qr == 1) && (<QrCodeScannerIcon />)}</label>
                      {(i.spice_level != null) && (
                          <ol style={{'background-color' : "rgb(215 221 214)" }} className="my-list style-3 underOl">
                          <li className="shopping__item" >
                          <label htmlFor="potatoes" className="shopping__label">
                            Spice Level : { i.spice_level}
                          </label>
                        </li>
                        </ol>  
                      )}
                        
                      <ol className="my-list style-3 underOl">
                        {  i.token_attributes && i.token_attributes.map(j => (
                         
                          <li className="shopping__item modifire">{ j.qty} X {j.name}</li>
                          
                         
                        ))}
                        </ol>
                        {(i.notes != null) && (
                              <ol style={{'background-color' : "rgb(236 231 177)" }} className="my-list style-3 underOl">
                              <li className="shopping__item" >
                              <label htmlFor="potatoes" className="shopping__label">
                                Special Instructions : { i.notes}
                              </label>
                            </li>
                            </ol>
                             
                          )}
                          {(i.textarea != null) && (i.textarea_label != null) && (
                              <ol style={{'background-color' : "rgb(236 231 177)" }} className="my-list style-3 underOl">
                              <li className="shopping__item" >
                              <label htmlFor="potatoes" className="shopping__label">
                              { i.textarea_label}  : { i.textarea}
                              </label>
                            </li>
                            </ol>
                          
                        )}
                    </li>
                  </div>
                    )
                ))}
              
            </ol>
            )}
          </div>
        </Box>
        
      </Modal>
      
    </div>
    
   
  );
};

export {ModalComponentWithOutTags} ;
