import React, { useState ,useEffect } from "react";
import useForm from "./useForm";
import { Link } from "react-router-dom";
import { useNavigate  } from "react-router-dom";
import Grid from '@mui/material/Grid'
import { withStyles } from "@material-ui/styles";
import config from '../../config';
import {Box,List,Typography,useTheme,Button,Tab,Tabs} from "@mui/material";
import { SnackbarProvider, useSnackbar } from 'notistack';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import validate from "./LoginFormValidationRules";


import UndoIcon from '@mui/icons-material/Undo';
const FontScreen = ({activeIndexn,handleChangesn}) => {
const [activeIndex, setActiveIndex] = useState(activeIndexn);
const { enqueueSnackbar } = useSnackbar();
const navigate = useNavigate()

const [fontSize, setFontSize] = useState( ReactSession.get("currentFont")) // Initial font size is medium

  const handleFontSizeChange = (event) => {

    setFontSize(event.target.value); // Update font size state based on selected radio button
    ReactSession.set("currentFont", event.target.value);
  };




useEffect(() => {
if(activeIndexn === 0){
  console.log("test00");
}if(activeIndexn === 1){
  console.log("test11");
}
  
}, [activeIndexn])


const onClickSubmit = async(data) => {

  const url = config.apiUrl + "kds-device-update";
  const getToken = ReactSession.get("authToken")
  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
    // Set headers
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken}`
    };
  try {
    const response = await axios.post(url, {
      font_size :fontSize
   },{headers});

   ReactSession.set("currentFont", fontSize);
   enqueueSnackbar('Device font updated successfully!!', {variant: 'success',anchorOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }},);
  setTimeout(() => {
    navigate("/")
  }, 5000);
  }
  catch (error) {
    if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized error
      ReactSession.set("currentTags", null);
      ReactSession.set("kdsName", null);
      ReactSession.set("authToken", null);
      ReactSession.set("userData", null);
      ReactSession.set("kdsName", null);
      ReactSession.set("userSetting", null);
      ReactSession.set("alltags", null);
      window.location.reload(); // Reload the page
    } else {
      // Handle other errors
      console.error(error);
    }
  }

  
}




 
   
    return (
      <div style={{ display: "flex", position: 'relative' }}>
        
  
        <div className="form-container outer flex justify-center items-center h-screen tabone">
              <div className="form-form">
                  <div className="form-form-wrap">
                      <div className="form-container">
                          <div className="form-content">
                              <div className="avatar avatar-xl">
                              
                              </div>
                              
                              <div className=" card-1">
                                  <div className="top">
                                  <div className="container">
                                            <h1>Font Size Management</h1>
                                            <div className="fontcls">
                                              <input
                                                type="radio"
                                                id="small"
                                                name="fontSize"
                                                value="text-base"
                                                checked={fontSize === 'text-base'}
                                                onChange={handleFontSizeChange}
                                              />
                                              <label htmlFor="small">Small</label>
                                            </div>
                                            <div className="fontcls">
                                              <input
                                                type="radio"
                                                id="medium"
                                                name="fontSize"
                                                value="text-lg"
                                                checked={fontSize === 'text-lg'}
                                                onChange={handleFontSizeChange}
                                              />
                                              <label htmlFor="medium">Medium</label>
                                            </div>
                                            <div className="fontcls">
                                              <input
                                                type="radio"
                                                id="large"
                                                name="fontSize"
                                                value="text-xl"
                                                checked={fontSize === 'text-xl'}
                                                onChange={handleFontSizeChange}
                                              />
                                              <label htmlFor="large">Large</label>
                                            </div>

                                        <button
                                          type="button"
                                          onClick={() =>onClickSubmit()}
                                          className="button btn nextbutton tagselectionbuttonunderscreen btn-primary login-form-button ant-btn is-block is-info is-fullwidth"
                                        >
                                          Submit
                                        </button>
                                            
                                  </div>
                               
                                          
                                  </div>
                              </div>
                              


                          </div>

                      </div>

                  </div>
              </div>
          </div>
      </div>
    );
  };
  
 
  

export default FontScreen;
